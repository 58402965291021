<div *ngIf="!loading && banners.length > 0" class="container-fluid seriesList">
    <div class="row flex-row flex-nowrap" [ngClass]="this.ThreeButtons ? 'resize':''">
        <drag-scroll [snap-offset]="0" #badges [snap-disabled]="true">
            <div class="item pb-3" drag-scroll-item *ngFor="let b of banners; index as i" [ngClass]="{'TwoVehicleSlider':Site.layoutFeatures?.includes('TwoVehicleSlider')}">
                <div class="card" >
                    <div class="card-img-top image">
                        <a href="{{b.Link}}"><img loading="lazy" class="d-block w-10" [ngClass]="FeatureImgClass"
                        src="{{imgadd(b.ImageURL)}}" alt="{{b.ImageURL}}"></a>
                    </div>
                    <div class="card-body">
                        <div class="topRow" [ngClass]="b.Buttons.length == 1 ? 'col-12':'col-6'">
                            <a *ngFor="let but of b.Buttons?.slice(0,2)" href="{{but.URL}}"><button class="btn-primary">{{but.Label}}</button></a>
                        </div>
                        <div class="col-12 bottomRow" [ngClass]="(b.Buttons.length < 3 && this.ThreeButtons) ? 'botPad':''">
                            <a *ngFor="let but of b.Buttons?.slice(2)" href="{{but.URL}}"><button class="btn-primary">{{but.Label}}</button></a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </drag-scroll>

        
    </div>
    <button aria-label="Prev" class="prev arrow" type="button" (click)="prev()" [ngClass]="{'TwoVehicleSliderArrow':Site.layoutFeatures?.includes('TwoVehicleSlider'), 'ThreeButtonArrows' : this.ThreeButtons}">
        <span class="icon-arrowleft"></span>
    </button>
    <button aria-label="Next" class="next arrow" type="button" (click)="next()" [ngClass]="{'TwoVehicleSliderArrow':Site.layoutFeatures?.includes('TwoVehicleSlider'), 'ThreeButtonArrows' : this.ThreeButtons}">
        <span class="icon-arrowright"></span>
    </button>
</div>